import React from "react"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import Background from "../../../assets/images/servicesHeader/seo_icon.svg"
import arrow from "../../../assets/images/servicesPages/arrowDown.png"
import sem_group from "../../../assets/images/servicesPages/sem-group.svg" 

export default () => (
    <div>
        <SEO title={'ICON Worldwide | Search Engine Optimization Services'} 
        description="We love driving real, measurable growth to our clients' marketing strategy, thought our strategic SEO approach. Start driving customers to your website now!"
        canonical={'https://icon-worldwide.com/services/seo'}/>
        <Header headerTitle="Search Engine Optimization" headerSubTitle="Making website visibility as easy as ever"  headerBackground={Background}/>
        <div id="servicesPagesInner">
            <div className="winningTeam">
                <div className="text">
                    <h2><span>Today’s</span> SEO <span>isn’t just about keyword counts or backlinks from high PageRank websites. 
                    It’s about</span> delivering content <span>that</span> search engines <span>want to</span> reward <span>and that</span> drives customers <span>to your</span> site.
                    <span>It’s about</span> being found <span>in all the right places</span> online, and <span>it’s about using that</span> visibility <span>to</span> drive real,
                    <span>measurable</span> growth <span>in your company’s</span> bottom line.</h2>
                </div>
            </div>
            <img className="arrowDown" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            <div className="normal-image"><img src={sem_group} alt="seo services, ICON Worldwide, maximising digital roip" title=" ICON Worldwide, seo services, maximising digital roi"/></div>
            <div className="twoColumns">
                <div className="block-two seo">
                    <h2>Our POV</h2>
                    <h3>Great content <span>is the essential</span> driver <span>of</span> traffic.</h3>
                    <ul>
                        <li>Consistently optimised against key-word audits, competitors and goals </li>
                        <li>Distributed content via social media, PR and newsletters </li>
                        <li>Consistently optimized against key-word audits, competitors and goals </li>
                        <li>Distributed content via social media, PR and newsletters </li>
                    </ul>
                    <p>SEO is a continuous process, results are hard to guarantee. Backend development needs 
                    to continuously support content via metatags, page linking, etc. Stagnation has a multiplier
                    effect which is hard to build back from</p>
                </div>

                <div className="block-two">
                    <ul className="seo-big-list">
                        <li><h3>SEO Campaign Management  </h3></li>
                        <li><h3>Link Profile Audit </h3> </li>
                        <li><h3>Site Crawl Review  </h3></li>
                        <li><h3>UX Evaluation  </h3></li>
                        <li><h3>Web Analytics Setup  </h3></li>
                        <li><h3>General SEO Consulting  </h3></li>
                        <li><h3>Infrastructure Analysis</h3></li>
                    </ul>
                </div>
            </div>
            <div className="yellowLine"></div>
            <ServicesBottomNav/>
        </div>
        <Footer noScrollbar="true"/>
    </div>
)